import {HttpClient} from '@angular/common/http';
import {from} from 'rxjs';
import {Logger} from './logger';


export class TranslateHttpLoader {

    // We do this to have an absolute path to the asset for build time hashing
    private langMap = {
        'en': '/assets/i18n/en.65a0b6337181140b0fd440b465608684.json'
    };

    constructor(private isBrowser:boolean,
                private httpClient:HttpClient,
                private logger:Logger,
                private isMobile:boolean) {
        this.logger = this.logger.getLogger('TranslateHttpLoader');
    }

    public getTranslation(lang:string) {
        if (this.isBrowser) {
            return this.httpClient.get(this.langMap[lang]);
        }
        else {
            return from(this.serverGet(lang));
        }
    }

    // For some strange reason it's not possible to ship assets with the server build
    private async serverGet(lang:string) {
        const fs        = await import('fs');
        const path      = await import('path');
        const __dirname = path.dirname(__filename);
        const langPath  = this.langMap[lang];
        const i18nPath  = `../browser`;
        const url       = path.join(__dirname, i18nPath, langPath);
        return fs.readFileSync(url, {encoding: 'utf-8'});
    }
}
