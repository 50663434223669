export let environment = {
    "envName": "prod",
    "appType": "desktop",
    "pwaName": "Pepstores",
    "version": "30.3.8+87",
    "config": {
        "api": {
            "websiteBasePath": "https://www.pepstores.com",
            "middlewareBasePath": "https://www.pepstores.com",
            "paymentShopperResultUrl": "https://magento2.pepstores.com/pp-hosted/secure/payment",
            "genericFormCompanyNo": "1",
            "genericFormOrigin": "pepstores.com",
            "genericFormContactUsSupplierDestID": "11",
            "genericFormContactUsCareerDestID": "10",
            "genericFormContactUsCustomerDestID": "4",
            "genericFormContactUsCorporateDestID": "6",
            "genericFormContactUsComplaintDestID": "17",
            "genericFormGetInsuranceDestID": "8",
            "genericFormSocialInvestmentDestID": "6",
            "genericFormContactUsPressDestID": "23",
            "newsletterAPIBrand": "pep",
            "emailPlaceholderReg": "^[0-9]+@(.+)?((magento2\\.pepstores\\.com)|(pepkorit\\.com))",
            "cdnBaseUrl": "https://www.pepstores.com/cdn-proxy/prod-pep-cdn",
            "cdnRootUrl": "/assets/cdn",
            "magentoImageBaseUrl": "https://www.pepstores.com/cdn-proxy/prod-pep-cdn/product-images/prod",
            "documentBaseUrl": "/assets/cdn/documents",
            "attributeStylesUrl": "/assets/cdn/attribute-styles/pepstores.com-attributes.cd6f67ee8cf9e2f172bdc957d0879e24.css",
            "attributeStylesFontName": "pepstores.com-attributes",
            "brandLogoImageBaseUrl": "https://www.pepstores.com/cdn-proxy/prod-pep-cdn/brand-logos/prod",
            "productAttributeAssetBaseUrl": "https://www.pepstores.com/cdn-proxy/prod-pep-cdn/product-attributes/prod",
            "storeFinderBrand": "pep",
            "tenant": "Pepstores",
            "careersUrl": "https://pep.mcidirecthire.com/default/External/CurrentOpportunities",
            "addThisTracker": "ra-5a5de69817fba874",
            "wordpressURL": "https://dev-wordpress.pepstores.com",
            "wordpressRootPages": [
                "pep"
            ],
            "wordpressRootPageMap": [
                {
                    "key": "pep",
                    "websiteBasePath": "https://www.pepstores.com"
                }
            ],
            "paxiNodeSelectorWidgetUrl": "https://map.paxi.co.za",
            "wordpressBlogPostsEnabled": false,
            "production": true,
            "firebaseUploadPath": "pepstores/dev",
            "firebaseConfig": [
                "QUl6YVN5RGxFUktEUWRWcWZHbkExZG5xcGtrNWJKMzhweEkxeFFj",
                "cGVwc3RvcmVzLXByb2QuZmlyZWJhc2VhcHAuY29t",
                "aHR0cHM6Ly9wZXBzdG9yZXMtcHJvZC5maXJlYmFzZWlvLmNvbQ==",
                "cGVwc3RvcmVzLXByb2Q=",
                "cGVwc3RvcmVzLXByb2QuYXBwc3BvdC5jb20=",
                "Mjg3MjQ5MDE3OTMz",
                "MToyODcyNDkwMTc5MzM6d2ViOmUzY2IxMTgwYTJlMzdjYTM1YTQwMDQ=",
                "Ry1FS1ZGTVdSSDk5",
                "QlBFMllGc2sydTc4MlJBaGpSRjB3UlNRR2J0dHBiX2UzNktYZVdod2pfeGp3TmJhMnJnVnZFZ2s4Sm5EVVhzNjlncHJGN2dNdXdlaW51SHBMN2RWV1Jn"
            ],
            "gtmTracker": "GTM-NLPJX9",
            "sentryDSN": "https://8335d90c79e84a4c9baa26eac4a677d8@o967548.ingest.sentry.io/6154702",
            "sentryAllowUrls": [
                "pepstores.com"
            ],
            "nativeAssetPath": "",
            "recaptcha": "6LcOMRkpAAAAAJzbszC5rE0c-BL0P6xax7VNhKI0",
            "imagekitProductImagesEnabled": true,
            "imagekitWordpressEnabled": true,
            "imagekitProductImagesUrl": "https://www.pepstores.com/imagekit/prod-pep-product-images",
            "imagekitWordpressMediaUrl": "https://www.pepstores.com/imagekit/prod-pep-wordpress/prod",
            "crazyeggScriptUrl": "//script.crazyegg.com/pages/scripts/0118/9176.js",
            "segmentifyEnabled": false,
            "storeCreditEnabled": false,
            "seoBase": {
                "title": "pepstores.com",
                "description": "Welcome to PEP, South Africa’s largest single brand retailer.  PEP provides a lifeline to local people with affordable, good quality merchandise.",
                "ogType": "website",
                "twitterType": "card",
                "twitterSiteUsername": null,
                "twitterAuthorUsername": null,
                "facebookAppId": null,
                "imagePath": null,
                "imageWidth": null,
                "imageHeight": null
            }
        },
        "customPageSEOData": [
            {
                "id": 0,
                "urlKey": "find-out-more/news",
                "pageTitle": "News",
                "seoTitle": "News Releases | Latest News | Find Out More | PEPtalk | PEP",
                "seoDescription": "Find out what is new at Pep, South Africa's largest single brand retailer.  PEP provides a lifeline to local people with affordable, good quality merchandise."
            },
            {
                "id": 1,
                "urlKey": "find-out-more/social-investment",
                "pageTitle": "Corporate Social Investment",
                "seoTitle": "Find Out More | Social Investment | PEP",
                "seoDescription": "Find out more about PEP's corporate social investment initiatives. PEP, South Africa's largest single brand retailer."
            },
            {
                "id": 2,
                "urlKey": "find-out-more/social-investment/application-terms",
                "pageTitle": "Corporate Social Investment Terms",
                "seoTitle": "Find Out More | Social Investment | PEP",
                "seoDescription": "Find out more about PEP's corporate social investment initiatives. PEP, South Africa's largest single brand retailer."
            },
            {
                "id": 3,
                "urlKey": "find-out-more/social-investment/application-form",
                "pageTitle": "Sponsorship Form",
                "seoTitle": "Find Out More | Social Investment Application | PEP",
                "seoDescription": "Apply for the corporate social investment programme. PEP, South Africa's largest single brand retailer."
            },
            {
                "id": 4,
                "urlKey": "more-info/contact-us"
            },
            {
                "id": 5,
                "urlKey": "find-out-more/news/pep-academy",
                "pageTitle": "PEP Academy: building the foundations for a better, brighter future"
            },
            {
                "id": 6,
                "urlKey": "find-out-more/news/brain-food",
                "pageTitle": "Brain food! Why eating the right foods is important for your child’s development"
            },
            {
                "id": 7,
                "urlKey": "find-out-more/news/bully-victim",
                "pageTitle": "Is your child a victim of bullying? Here’s what to do"
            },
            {
                "id": 8,
                "urlKey": "find-out-more/news/confidence-your-child",
                "pageTitle": "Confidence: your child’s key to success"
            },
            {
                "id": 9,
                "urlKey": "find-out-more/news/fitness-is-fun",
                "pageTitle": "Why fitness is fun – and necessary – for your little one"
            },
            {
                "id": 10,
                "urlKey": "find-out-more/news/child-screen",
                "pageTitle": "Is your child’s screen time turning into scream time for you as parent?"
            }
        ],
        "defaultLatLng": {
            "latitude": -33.919706,
            "longitude": 18.419986
        },
        "headOfficeLatLng": {
            "latitude": -33.935238,
            "longitude": 18.605943
        },
        "redirects": []
    },
    "uiConfig": {
        "desktopMainMenu": {
            "items": [
                {
                    "type": 0,
                    "locale": "menu.newIn",
                    "link": "/page/new-in",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "defaultWidth": 250,
                                "items": [
                                    {
                                        "type": 0,
                                        "locale": "menu.newIn.justArrived",
                                        "link": "/page/new-in/just-arrived"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.newIn.homeInspo",
                                        "link": "/page/new-in/home-inspo"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.newIn.cellularDeals",
                                        "link": "/page/new-in/cellular-deals"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.newIn.essentials",
                                        "link": "/page/new-in/essentials"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.catalogs",
                                        "link": "/page/catalogues"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/ladies",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "defaultWidth": 250,
                                "items": [
                                    {
                                        "type": 4,
                                        "parentUrlKeyPath": "/ladies"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/men",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "defaultWidth": 250,
                                "items": [
                                    {
                                        "type": 4,
                                        "parentUrlKeyPath": "/men"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/school",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "defaultWidth": 250,
                                "items": [
                                    {
                                        "type": 4,
                                        "parentUrlKeyPath": "/school"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/babies-kids",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "defaultWidth": 250,
                                "items": [
                                    {
                                        "type": 4,
                                        "parentUrlKeyPath": "/babies-kids"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/order-collect"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/home",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "defaultWidth": 250,
                                "items": [
                                    {
                                        "type": 4,
                                        "parentUrlKeyPath": "/home"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/cell",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "defaultWidth": 250,
                                "items": [
                                    {
                                        "type": 0,
                                        "locale": "Click & Collect",
                                        "link": "https://www.pepcell.com/"
                                    },
                                    {
                                        "type": 4,
                                        "parentUrlKeyPath": "/cell"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/order-collect"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 0,
                    "locale": "menu.pepmoney",
                    "link": "/page/pep-money",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "defaultWidth": 250,
                                "items": [
                                    {
                                        "type": 0,
                                        "locale": "menu.more.pepAccount",
                                        "link": "/page/pep-account/what-a-pep-account-can-do-for-you"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.pepmoney.apply",
                                        "link": "/page/pep-money/apply-for-a-loan"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.pepmoney.moneyTransfers",
                                        "link": "/page/pep-money/money-transfers"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.pepmoney.buyTokens",
                                        "link": "/page/pep-money/buy-tokens/pepmoney-card-tokens"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.pepmoney.payAccounts",
                                        "link": "/page/pep-money/pay-accounts/dstv"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.pepmoney.collectTickets",
                                        "link": "/page/pep-money/collect-tickets/collect-tickets"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.pepmoney.paxi",
                                        "link": "/page/pep-money/paxi/what-is-paxi"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.pepmoney.oneVoucher",
                                        "link": "/page/pep-money/1voucher/what-is-1voucher"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 0,
                    "locale": "menu.laybyBuddy",
                    "link": "/page/lay-by-buddy"
                },
                {
                    "type": 0,
                    "locale": "menu.plusMorePage",
                    "link": "/page/plusmore",
                    "cssClasses": "plusmore",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "defaultWidth": 250,
                                "items": [
                                    {
                                        "type": 0,
                                        "locale": "menu.plusMoreDeals",
                                        "link": "/page/plusmore/deals"
                                    },
                                    {
                                        "type": 0,
                                        "locale": "menu.plusMoreFaq",
                                        "link": "/page/plusmore/plusmore-faqs/how-can-i-join"
                                    }
                                ]
                            }
                        ]
                    }
                }
            ],
            "sizes": {
                "title": 0,
                "subTitle": 0,
                "buttonLarge": 0,
                "buttonStd": 0,
                "divider": 0,
                "autoLayoutGap": 0
            }
        },
        "footerMenuElements": [
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "localeKey": "menu.categories",
                    "children": [
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/ladies",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/men",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/babies-kids",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/school",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/home",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/cell",
                            "enableChildren": false
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "localeKey": "menu.pepmoney",
                            "builtInPageType": "full-link",
                            "pathAfterType": "/page/pep-money"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.more.layBye",
                            "pathAfterType": "/page/lay-by"
                        }
                    ]
                }
            ],
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "builtInPageType": "contact-us",
                    "localeKey": "menu.help",
                    "children": [
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "contact-us",
                            "localeKey": "menu.contactUs"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "store-finder",
                            "localeKey": "menu.findAStore"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "page",
                            "localeKey": "menu.corporateAndSocial",
                            "pathAfterType": "1"
                        }
                    ]
                }
            ],
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "localeKey": "menu.more",
                    "builtInPageType": "full-link",
                    "pathAfterType": "/page/competitions",
                    "children": [
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.more.competitions",
                            "pathAfterType": "/page/competitions"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.more.feelGoodFm",
                            "pathAfterType": "/page/feelgood-fm"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.more.careers",
                            "pathAfterType": "https://pep.mcidirecthire.com/default/External/CurrentOpportunities"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.more.layBye",
                            "pathAfterType": "/page/lay-by"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.more.blog",
                            "pathAfterType": "/page/pepcares"
                        }
                    ]
                }
            ],
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "localeKey": "menu.terms",
                    "builtInPageType": "full-link",
                    "pathAfterType": "/page/terms-and-conditions/general#general",
                    "children": [
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms.general",
                            "pathAfterType": "/page/terms-and-conditions/general#general"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms.competitions",
                            "pathAfterType": "/page/terms-and-conditions/competitions"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms.returnAndRefund",
                            "pathAfterType": "/page/terms-and-conditions/general#pep-return-and-refund-policy"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms.personalInfo",
                            "pathAfterType": "/page/terms-and-conditions/general#pep-marketing-and-processing-of-personal-information-consent"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms.plusMoreTerms",
                            "pathAfterType": "/page/terms-and-conditions/plusmore"
                        }
                    ]
                }
            ]
        ],
        "catalogs": [
            {
                "label": "PEPstore Catalogue",
                "url": "https://www.yumpu.com/en/embed/view/oAndig0p4qPhGVEM",
                "image": "/catalogs/2019-12/pepstore-catalogue-14.jpg",
                "startDate": "2019-12-08T00:00:00.000Z",
                "endDate": "2022-12-30T00:00:00.000Z"
            },
            {
                "label": "PEPhome Catalogue",
                "url": "https://www.yumpu.com/en/embed/view/kg5vBHl49Jb306To",
                "image": "/catalogs/2019-12/pephome-catalogue-14.jpg",
                "startDate": "2019-12-08T00:00:00.000Z",
                "endDate": "2022-12-30T00:00:00.000Z"
            },
            {
                "label": "Best deals with PEPcell Catalogue",
                "url": "https://www.yumpu.com/en/embed/view/eCoturQLK7j02IV6",
                "image": "/catalogs/2019-12/pepcell-catalogue-14.jpg",
                "startDate": "2019-12-08T00:00:00.000Z",
                "endDate": "2022-12-30T00:00:00.000Z"
            }
        ],
        "disablePromotionalPages": false,
        "homePageBannerRatio": {
            "width": 1480,
            "height": 646
        },
        "promotionalPageBannerRatio": {
            "width": 1480,
            "height": 550
        },
        "subPromotionalPageBannerRatio": {
            "width": 1480,
            "height": 275
        },
        "productListSortFields": [
            {
                "localeKey": "product.sort.priceLowToHigh",
                "sortField": "price",
                "sortDescending": false
            },
            {
                "localeKey": "product.sort.priceHighToLow",
                "sortField": "price",
                "sortDescending": true
            },
            {
                "localeKey": "product.sort.latest",
                "sortField": "position",
                "sortDescending": false
            }
        ],
        "attributeCodes": [
            {
                "name": "Only At PEP",
                "type": "NAME_ONLY",
                "singleAttrCodes": [
                    {
                        "code": "exclusive",
                        "icon": "medal-outline-star"
                    }
                ],
                "singleIncludeCodes": []
            },
            {
                "name": "Comes with",
                "type": "NAME_AND_ICON",
                "singleAttrCodes": [
                    {
                        "code": "bluetooth_speaker",
                        "icon": "music-speaker-streamline"
                    },
                    {
                        "code": "data_100mb_12mnths",
                        "icon": "plus"
                    },
                    {
                        "code": "data_100mb_6mnths",
                        "icon": "plus"
                    },
                    {
                        "code": "data_200mb_6mnths",
                        "icon": "plus"
                    },
                    {
                        "code": "free_airtime",
                        "icon": "plus"
                    },
                    {
                        "code": "free_cover",
                        "icon": "mobile"
                    },
                    {
                        "code": "free_data",
                        "icon": "plus"
                    },
                    {
                        "code": "free_sdcard",
                        "icon": "sd-card"
                    },
                    {
                        "code": "free_stand",
                        "icon": "mobile"
                    },
                    {
                        "code": "free_starter_pack",
                        "icon": "mobile"
                    },
                    {
                        "code": "headset",
                        "icon": "headphone"
                    },
                    {
                        "code": "memory_card_included",
                        "icon": "sd-card"
                    },
                    {
                        "code": "otg_adapter",
                        "icon": "plus"
                    },
                    {
                        "code": "screen_protector",
                        "icon": "mobile"
                    },
                    {
                        "code": "screen_wipe",
                        "icon": "mobile"
                    },
                    {
                        "code": "tempered_glass",
                        "icon": "mobile"
                    }
                ],
                "singleIncludeCodes": []
            },
            {
                "name": "Features",
                "type": "NAME_AND_ICON",
                "singleAttrCodes": [
                    {
                        "code": "alarm",
                        "icon": "alarm"
                    },
                    {
                        "code": "battery",
                        "icon": "battery-full"
                    },
                    {
                        "code": "bluetooth",
                        "icon": "bluetooth"
                    },
                    {
                        "code": "camera",
                        "icon": "camera"
                    },
                    {
                        "code": "connects_10_devices",
                        "icon": "sitemap"
                    },
                    {
                        "code": "dual_sim",
                        "icon": "sim-card"
                    },
                    {
                        "code": "single_sim",
                        "icon": "sim-card"
                    },
                    {
                        "code": "easypress_playstartup",
                        "icon": "button-check"
                    },
                    {
                        "code": "facebook",
                        "icon": "facebook"
                    },
                    {
                        "code": "flash",
                        "icon": "flash"
                    },
                    {
                        "code": "games",
                        "icon": "gamepad"
                    },
                    {
                        "code": "google_playstore",
                        "icon": "play"
                    },
                    {
                        "code": "handset_ram_size",
                        "icon": "ram"
                    },
                    {
                        "code": "internet_browser",
                        "icon": "web"
                    },
                    {
                        "code": "memory_card_slot",
                        "icon": "markdown"
                    },
                    {
                        "code": "mp3_player",
                        "icon": "ipod"
                    },
                    {
                        "code": "os",
                        "icon": "android"
                    },
                    {
                        "code": "radio",
                        "icon": "radio-tower"
                    },
                    {
                        "code": "screen",
                        "icon": "phone"
                    },
                    {
                        "code": "speakerphone",
                        "icon": "sound-alt"
                    },
                    {
                        "code": "torch",
                        "icon": "flashlight"
                    },
                    {
                        "code": "twitter",
                        "icon": "twitter"
                    },
                    {
                        "code": "vibrate",
                        "icon": "phone-home"
                    },
                    {
                        "code": "voicecall",
                        "icon": "microphone"
                    },
                    {
                        "code": "whatsapp",
                        "icon": "whatsapp"
                    },
                    {
                        "code": "wifi",
                        "icon": "wifi-3"
                    }
                ],
                "singleIncludeCodes": []
            }
        ],
        "addToCartCategoryIDs": null
    },
    "data": {
        "contactEmail": "info@pepstores.com",
        "contactNumber": "0860 737 000",
        "facebookLink": "https://www.facebook.com/PEPSouthAfrica",
        "youTubeLink": "https://www.youtube.com/user/PEPOnlineTV",
        "instagramLink": "https://www.instagram.com/pep_southafrica/",
        "linkedInLink": "https://za.linkedin.com/company/pep-stores"
    },
    "build": {
        "implPath": "src/implementation/pep/main/desktop",
        "addNoIndex": false
    }
};